<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { EVENT } from '@/libs/event-bus'

export default {
  name: 'StorageSync',
  props: ['product', 'autoFixQuantityMode'],
  data() {
    return {
      storageValue: null,
      synced: false,
      loading: false,
    }
  },
  methods: {

    async syncProduct(id, autoFix) {
      if (this.loading || autoFix && this.synced) {
        return
      }

      try {
        this.loading = true
        this.storageValue = await this.$store.dispatch(
          'shop/syncProduct',
          {
            id,
            autoFix,
          },
        )
      } finally {
        this.loading = false
      }

      if (autoFix) {
        this.synced = true
        await this.$store.dispatch(
          'shop/updateProduct',
          {
            ...this.product,
            quantity: this.storageValue,
          },
        )
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Товар синхронизирован со складом',
            variant: 'success',
          },
        })
      }
    },
    syncByBus() {
      return this.syncProduct(this.product.id, true)
    },
  },
  mounted() {
    this.$eventBus.$on(EVENT.STORAGE.SYNC, this.syncByBus)
  },
  beforeDestroy() {
    this.$eventBus.$off(EVENT.STORAGE.SYNC, this.syncByBus)
  },
}
</script>

<template>
  <span title="Получить кол-во на складе (зажать ctrl, чтобы автоматически синхронизировать в магазине)"
        :style="autoFixQuantityMode ? 'color: red': ''">
    <feather-icon
      @click="syncProduct(product.id, autoFixQuantityMode)"
      icon="RefreshCwIcon"
      class="mr-50 ml-50"
      :class="{'rotation': loading}"
    />
  {{ storageValue }}
  </span>
</template>

<style scoped lang="scss">
.rotation {
  display: inline-block;
  animation: .5s linear infinite rotate;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>