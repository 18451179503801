<template>
  <div class="card">
<!--    <filter-products />-->
    <div class="card-body">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Поиск</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
            @change="searchProducts"
          />
          <b-button
            title="Синхронизировать все товары со страницы"
            class="ml-1"
            @click="$eventBus.$emit(EVENT.STORAGE.SYNC)"
          >
            <feather-icon
              icon="RefreshCwIcon"
              size="16"
            />
          </b-button>
        </div>
      </b-form-group>
      <div class="product-container">
        <vue-good-table
          :columns="columns"
          :rows="rows"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <div
              :class="{
                bottom:
                  $route.query &&
                  $route.query.sorting &&
                  $route.query.sorting.endsWith('asc') &&
                  $route.query.sorting.startsWith(props.column.field),
                top:
                  $route.query &&
                  $route.query.sorting &&
                  $route.query.sorting.endsWith('desc') &&
                  $route.query.sorting.startsWith(props.column.field),
              }"
              class="my-span"
              @click="handleColumnClick(props.column)"
            >
              {{ props.column.label }}
            </div>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-b-modal.modal-primary
                    variant="outline-primary"
                    @click="copyAssortmentId(props.row)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Копировать ID связи</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.modal-primary
                    variant="outline-primary"
                  >
                  <router-link
                    :to="{ name: 'product-info', params: { id: props.row.id } }"
                    variant="outline-primary"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Редактировать</span>
                  </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.modal-primary
                    variant="outline-primary"
                    @click="acceptDelete(props.row.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Удалить</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <span v-else-if="props.column.field === 'quantity'">
              {{ props.formattedRow[props.column.field] }}
              <storage-sync
                :product="props.row"
                :autoFixQuantityMode="autoFixQuantityMode"
              />
            </span>
            <span v-else-if="props.column.field === 'isHidden'">
              <b-form-checkbox
                v-model="props.row.isHidden"
                checked="true"
                class="custom-control-primary"
                name="check-view"
                switch
                @change="switchItemVisibility(props.row.id, props.row.isHidden)"
              />
            </span>
            <span v-else-if="props.column.field === 'price'">
              {{ formatPrice(props.row.price) }}</span
            >
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <infinite-loading
          spinner="bubbles"
          @infinite="infiniteScroll"
        >
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import InfiniteLoading from 'vue-infinite-loading'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StorageSync from '@/views/shop/StorageSync.vue'
import FilterProducts from './FilterProducts.vue'
import { EVENT } from '@/libs/event-bus'
import crud_module from '@/libs/crud_module'
import { props } from 'vue-echarts'

const product_module = crud_module({
  entity_name: 'product',
})

export default {
  components: {
    BFormCheckbox,
    StorageSync,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    InfiniteLoading,
    BButton,
    FilterProducts,
  },
  data() {
    return {
      autoFixQuantityMode: false,
      searchTerm: '',
      selected: [],
      currentDelete: null,
      sortAsc: null,
      skipCount: 0,
      maxResultCount: 30,
      addNewDataSidebar: false,
      addNewOptionSidebar: false,
      optionData: {},
      rows: [],
      columns: [
        {
          label: 'Название товара',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Код',
          field: 'code',
          sortable: false,
          width: '70px',
        },
        {
          label: 'Количество',
          field: 'quantity',
          sortable: false,
        },
        {
          label: 'Скрыт',
          field: 'isHidden',
          sortable: false,
        },
        {
          label: 'Цена',
          field: 'price',
          sortable: false,
        },
        {
          label: 'Действие',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    EVENT() {
      return EVENT
    },
    categories() {
      return this.$store.state.shop.categories
    },
  },
  created() {
    this.$store.dispatch('shop/fetchCategories')
    if (this.$route.query.product) {
      this.openEditPopupFromUrl()
    }
  },
  methods: {
    async switchItemVisibility(id, isHidden) {
      await product_module
        .patchItem(id, {
          isHidden,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'BellIcon',
              text: 'Товар изменен',
              variant: 'success',
            },
          })
          this.$emit('closeModal', false)
        })
    },
    searchProducts() {
      this.skipCount = 0
      this.maxResultCount = 30
      const payload = {
        offset: this.skipCount,
        limit: this.maxResultCount,
        search: this.searchTerm,
        doNotCollapseSimilar: true,
      }
      this.$store.dispatch('shop/fetchDataListItems', payload).then(res => {
        // TODO hotfix, abort controller should be implemented
        this.skipCount = 0
        this.maxResultCount = 30
        if (this.$store.state.shop.products.length > 0) {
          this.rows = [...this.$store.state.shop.products]
          this.skipCount += this.maxResultCount
        }
      })
    },
    async openEditPopupFromUrl() {
      if (!this.$route.query.product) {
        return
      }
      const productId = this.$route.query.product
      const product = await this.$store.dispatch(
        'shop/fetchDataById',
        productId,
      )
      if (!product) await this.$router.replace(this.$route.path)
      else this.editData(product)
    },
    formatPrice(data) {
      return Math.trunc(data / 100)
    },
    async infiniteScroll($state) {
      const { sorting } = this.$route.query
      const payload = {
        offset: this.skipCount,
        limit: this.maxResultCount,
        search: this.searchTerm,
        doNotCollapseSimilar: true,
        sorting: sorting || '',
      }
      this.$store
        .dispatch('shop/fetchDataListItems', payload)
        .then(res => {
          if (this.$store.state.shop.products.length > 0) {
            this.rows.push(...this.$store.state.shop.products)
            this.skipCount += this.maxResultCount
            $state?.loaded()
          } else {
            $state?.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSortIcon() {
      if (this.sortAsc === true) {
        return 'bi bi-arrow-up'
      } else {
        return 'bi bi-arrow-down'
      }
    },
    async handleColumnClick(column) {
      const sortableFields = ['name', 'code', 'quantity', 'isHidden', 'price']
      if (!sortableFields.includes(column.field)) {
        return
      }
      this.rows = []
      this.skipCount = 0
      this.maxResultCount = 30
      const currentSorting = this.$route.query.sorting || ''
      const [currentField, currentDirection] = currentSorting.split(' ')
      let newDirection = 'asc'
      this.sortAsc = true
      if (currentField === column.field && currentDirection === 'asc') {
        newDirection = 'desc'
        this.sortAsc = false
      }
      const updatedQuery = {
        ...this.$route.query,
        sorting: `${column.field} ${newDirection}`,
      }
      this.$router.replace({ query: updatedQuery })
      await this.infiniteScroll()
    },
    deleteData() {
      this.$store.dispatch('shop/removeItem', this.currentDelete).then(() => {
        const index = this.rows.findIndex(el => el.id === this.currentDelete)
        this.rows.splice(index, 1)
      })
    },
    copyAssortmentId(product) {
      navigator.clipboard
        .writeText(product.assortmentId)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Скопировано',
              icon: 'BellIcon',
              text: 'ID скопирован в буфер обмена',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка копирования',
              icon: 'BellIcon',
              text: 'ID не был скопирован в буфер обмена',
              variant: 'error',
            },
          })
          console.error('Ошибка при копировании: ', err)
        })
    },
    editData(product) {
      this.$router.push({ name: 'product-info', params: { id: product.id } })
    },
    acceptDelete(data) {
      this.currentDelete = data
      this.$vs.dialog({
        color: 'warning',
        title: 'Удаление товара',
        text: 'Вы уверены, что хотите удалить товар?',
        accept: this.deleteData,
        acceptText: 'Да',
      })
    },
    autoFixModeKeyDownHandler(e) {
      if (e.key === 'Control') {
        this.autoFixQuantityMode = true
      }
    },
    autoFixModeKeyUpHandler(e) {
      if (e.key === 'Control') {
        this.autoFixQuantityMode = false
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.autoFixModeKeyDownHandler)
    document.addEventListener('keyup', this.autoFixModeKeyUpHandler)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.autoFixModeKeyDownHandler)
    document.removeEventListener('keyup', this.autoFixModeKeyUpHandler)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
.container {
  padding: 15px 20px;
}

.card {
  display: flex;
  flex-direction: row;
}

.my-span {
  display: flex;
  flex-direction: row;
  user-select: none;
  cursor: pointer;
  position: relative;
  &.top {
    &::after {
      content: '';
      position: absolute;
      height: 0px;
      width: 0px;
      top: 50%;
      margin-top: -7px;
      border-bottom: 5px solid rgba(0, 0, 0, 0);
      right: 6px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom-color: #6e6b7b;
    }
  }
  &.bottom {
    &::before {
      right: 6px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      margin-bottom: -7px;
      border-top: 5px solid #6e6b7b;
      content: '';
      position: absolute;
      height: 0px;
      width: 0px;
      top: 50%;
    }
  }
}
</style>
