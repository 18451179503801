<template>
  <div class="card col-md-2 pl-0">
    <div class="scrl-content filter-sidebar fs-viscon">
      <div class="p-1 d-flex flex-column">
        <div
          v-for="(option, index) in options"
          :key="'filter_' + index"
          class="pt-1 user-select-none"
          role="button"
        >
          <div
            v-if="option.in_filter"
            class="category"
            @click="toggleOption(index)"
          >
            {{ option.name }}
          </div>
          <b-collapse :visible="index === openOptionIndex">
            <ul class="list-group options">
              <li
                v-for="(optionValue, optionValueIdx) in option.values"
                :style="option.name === 'цвет' ? 'flex-wrap:wrap;' : ''"
                style="padding: 12px 7px"
                :key="'filter_' + optionValueIdx"
                class="list-group-item"
              >
                <b-form-checkbox
                  :id="'filter_' + optionValueIdx + '_value' + optionValueIdx"
                  v-model="selectedOptionList"
                  :value="`${option.uuid}:${optionValue.uuid}`"
                  class="custom-input"
                >
                  {{ optionValue.name }}
                </b-form-checkbox>
              </li>
            </ul>
          </b-collapse>
        </div>
      </div>
      <b-button
        class="ml-1"
        variant="outline-danger"
        @click="resetOptions"
      >
        <i class="far fa-redo"></i> Сбросить
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BCheckbox,
  BCollapse,
} from 'bootstrap-vue'
import axios from '@axios'
export default {
  components: {
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BCheckbox,
    BButton,
    BCollapse,
  },
  data() {
    return {
      openOptionIndex: null,
      selectedOptionList: [],
      options: [],
    }
  },
  async mounted() {
    if (process.server) {
      return
    }
    try {
      const res = await axios.get('/product/render-filter')
      if (res && res.data && res.data.filters && res.data.filters.rows) {
        this.options = res.data.filters.rows
      } else {
        console.error('Unexpected response structure:', res)
      }
    } catch (error) {
      console.error('Error fetching filters:', error)
    }
  },
  methods: {
    toggleOption(index) {
      console.log('index', index)
      this.openOptionIndex = this.openOptionIndex === index ? null : index
    },
    resetOptions() {
      this.selectedOptionList = []
    },
  },
}
</script>
